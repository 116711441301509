export default {
  name: 'sdDetailsData',
  props: ['fmsUnitId', 'accessButtons','sdVendorId','sdVendorSiteId'],
  data() {
    return {
      loader: false,
      sdDetailsData: [],
      sdDetailsFields: [
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_num'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'payment_ref_no'
        },
        {
          key: 'document_num'
        },
        {
          key: 'ap_invoice_id'
        },
        {
          key: 'paid_amount'
        }
      ],
      payload: null,
      downloadPayload: null
    };
  },
  mounted() {
    this.getOwnershipDetailsDataBySd();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.downloadPayload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'masterData/getOwnershipDetailsDataBySd',
            'SD Details',
            () => (this.loader = false),
            this.sdDetailsData
          );
        }
      }
    });
  },
  methods: {
    getOwnershipDetailsDataBySd() {
      const payload = {
        fms_unit_id: this.fmsUnitId,
        vendor_id: this.sdVendorId,
        vendor_site_id: this.sdVendorSiteId 
      };
      this.downloadPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('masterData/getOwnershipDetailsDataBySd', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.sdDetailsData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
